import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Howard Wire" />
    <Container>
      <h1>Contact Howard Wire</h1>
      <Row>
        <Col lg={6}>
          <p>Awesome. Thank you! Someone will get back to you as soon as possible. </p>
        </Col>
        <Col lg={6}>
          <p>We are located at:</p>
          <p>
            28976 Hopkins Street, Suite A,<br />
            Hayward, CA 94545
          </p>

          <p>You can reach us at:</p>
          <p>
            (800) 969-3559<br />
            (510) 887-8787
          </p>
          <p><a href="mailto:sales@howardwire.com">sales@howardwire.com</a></p>
        </Col>
      </Row>
     
    </Container>
    

  </Layout>
)

export default IndexPage
